<template>
  <PhonelineSetupForm v-if="!error" :is-loading="pending" :offer="offer" />

  <BouyguesError v-else @try-again="handleTryAgain" />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { onMounted } from 'vue'

import { deleteService } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import BouyguesError from '~/scopes/checkout/components/BouyguesError/BouyguesError.vue'
import { useBouyguesStore } from '~/scopes/checkout/stores/bouyguesStore'
import { HOME } from '~/scopes/home/route-names'

import { useCartStore } from '../../stores/cartStore'

import PhonelineSetupForm from './components/PhonelineSetupForm/PhonelineSetupForm.vue'
import { useBouyguesOffer } from './composables/useBouyguesOffer'

const route = useRoute()
const router = useRouter()
const tracking = useTracking()

const cartStore = useCartStore()

const { setAccessToken } = useBouyguesStore()

const {
  offer,
  error,
  pending,
  refresh: refreshOffer,
} = useBouyguesOffer(cartStore.bouyguesMobilePlan?.id)

async function removeOfferFromCart(offerId?: string) {
  return $httpFetch(deleteService, { body: { offerId } })
}

async function handleTryAgain() {
  if (error.value && error.value.statusCode === 404) {
    await removeOfferFromCart(cartStore.bouyguesMobilePlan?.id)

    return router.push({ name: HOME })
  }

  return refreshOffer()
}

onMounted(() => {
  if (route.query?.accessToken && typeof route.query.accessToken === 'string') {
    setAccessToken(route.query.accessToken)
  }

  if (typeof route.name === 'string') {
    tracking.trackFunnel(cartStore.trackingData(route.name))
  }
})
</script>
